<template lang="html">
  <li class="nav-item dropdown">
    <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Кошик
      <span v-if="!loadings.products && products.length > 0">
        ({{products.length}})
      </span>
      <span v-else-if="!loadings.products && products.length == 0">
        (порожньо)
      </span>
      <span v-else>
        <!--  -->
      </span>
    </a>

    <div class="dropdown-menu dropdown-cart dropdown-menu-right">
      <div class="h6 dropdown-header" v-if="!loadings.products && products.length > 0">
        Список товарів
      </div>

      <ul class="list-group list-group-flush mb-3" v-if="!loadings.products && products.length > 0">
        <li v-for="product in products" :key="product.id" class="list-group-item d-flex justify-content-between align-items-center">
          <span class="text-truncate">
            <a :href="`/products/${product.url_path}`" class="text-dark">
              {{product.title}}
            </a>
            <div class="small text-muted">
              {{product.vendor_code}}

              <a href="javascript:void(0)" @click="removeFromCart(product.id)" class="ml-3">
                Видалити
              </a>
            </div>
          </span>
          <span class="text-nowrap ml-4" v-if="product.discount">
            <div class="small text-muted">
              <strike>
                {{product.total_price}}
              </strike>
            </div>
            {{product.discount.total_price}}
            <div class="small text-muted">
              {{product.quantity}} шт.
            </div>
          </span>
          <span class="text-nowrap ml-4" v-else>
            {{product.total_price}}
            <div class="small text-muted">
              {{product.quantity}} шт.
            </div>
          </span>
        </li>
      </ul>

      <ul class="list-group list-group-flush" v-else-if="!loadings.products && products.length == 0">
        <li class="list-group-item border-0 pb-1">Кошик порожній</li>
      </ul>

      <ul class="list-group list-group-flush" v-else>
        <li class="list-group-item">
          <i class="fa fa-spinner fa-spin"></i>
          Завантаження
        </li>
      </ul>

      <div class="d-flex justify-content-between pr-3 pb-2 pl-3">
        <a href="/cart/overview" class="btn btn-success" v-if="!loadings.products && products.length > 0">
          Перейти
        </a>

        <a @click="emptyTrash" href="javascript:void(0)" class="btn btn-outline-black" v-if="!loadings.products && products.length > 0">
          Очистити
        </a>
      </div>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      products: 'carts/products',

      loadings: 'carts/loadings'
    })
  },

  created() {
    this.getAllProducts()
  },

  methods: {
    ...mapActions({
      getAllProducts: 'carts/getAllProducts'
    }),

    removeFromCart(product_id) {
      this.$store.dispatch('products/removeFromCart', product_id).then(() => {
        this.$noty.success(`Товар успішно видалено з вашого кошика`)
        this.getAllProducts()
      }).catch((errors) => {
        this.$noty.error(`Товар не видалено з кошика`)
        this.$noty.error(errors)
        console.error(errors)
      })
    },

    emptyTrash() {
      this.$store.dispatch('carts/empty').then(() => {
        this.$noty.success(`Кошик був очищений`)
        this.getAllProducts()
      }).catch((errors) => {
        this.$noty.error(`Кошик не був очищений`)
        this.$noty.error(errors)
        console.error(errors)
      })
    }
  },

  components: {
  }
}
</script>
