<template>
  <div>
    <div class="h5 mb-3" v-if="loadings.products.started && !loadings.products.results">
      Результат пошуку
    </div>

    <div class="small text-muted" v-if="loadings.products.started && !loadings.products.results && products.result.length === 0">
      Не вдалося знайти товари за вашим запитом
    </div>

    <div class="list-group">
      <a v-for="product in products.result" :href="`/products/${product.url_path}`" :key="product.vendor_code" class="list-group-item list-group-item-action flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">

          <div class="d-flex flex-row align-items-center">
            <div class="mr-3">
              <a :href="`/products/${product.url_path}`" class="text-dark">
                <img :src="product.image" :alt="product.title" width="32">
              </a>
            </div>

            <div class="text-truncate">
              <a :href="`/products/${product.url_path}`" class="text-dark">
                {{product.title}}
              </a>
              <div class="small text-muted">
                {{product.vendor_code}}
              </div>
            </div>
          </div>

          <div v-if="!product.combinations_mode">
            <span class="text-nowrap" v-if="product.discount">
              <div class="small text-muted">
                <strike>
                  {{product.price}}
                  {{current_currency.symbol}}
                </strike>
              </div>
              {{product.discount.total_price}}
              <div class="small text-muted">
                {{product.quantity}} шт.
              </div>
            </span>

            <span class="text-nowrap" v-else>
              {{product.price}}
              {{current_currency.symbol}}
              <div class="small text-muted">
                {{product.quantity}} шт.
              </div>
            </span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    current_currency: {
      type: Object,
      required: true
    }
  },

  computed: mapGetters({
    products: 'search/products',
    loadings: 'search/loadings'
  })
}
</script>
