import Vue from 'vue'
import Vuex from 'vuex'
import Lodash from 'lodash'

import * as actions from './actions'
import * as getters from './getters'

import carts from './modules/carts/index'
import products from './modules/products/index'

import orders from './modules/orders/index'
import addresses from './modules/addresses/index'
import carriers from './modules/carriers/index'
import payments from './modules/payments/index'

import countries from './modules/countries/index'

import search from './modules/search/index'
import single_change from './modules/single_change/index'

Vue.use(Vuex)
Vue.use(Lodash)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  actions,
  getters,
  modules: {
    carts,
    products,

    orders,
    addresses,
    carriers,
    payments,

    countries,

    search,
    single_change
  },
  strict: debug
})
