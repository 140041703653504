export default {
  ua: {
    vee_validate: {
      custom: {
        first_name: {
          required: () => 'Поле "Ім\'я" обов’язкове для заповнення',
          min: (field, num) => `Поле "Ім\'я" не повинно бути менше ${num} символів`,
          max: (field, num) => `Поле "Ім\'я" не повинно бути більше ${num} символів`
        },
        last_name: {
          required: () => 'Поле "Прізвище" обов’язкове для заповнення',
          min: (field, num) => `Поле "Прізвище" не повинно бути менше ${num} символів`,
          max: (field, num) => `Поле "Прізвище" не повинно бути більше ${num} символів`
        },
        middle_name: {
          required: () => 'Поле "По батькові" обов’язкове для заповнення',
          min: (field, num) => `Поле "По батькові" не повинно бути менше ${num} символів`,
          max: (field, num) => `Поле "По батькові" не повинно бути більше ${num} символів`
        },
        email: {
          required: () => 'Поле "E-mail" обов’язкове для заповнення',
          email: () => 'Невірно вказаний E-mail'
        },
        phone: {
          required: () => 'Поле "Телефон" обов’язкове для заповнення',
          numeric: () => 'Телефон має складатися лише з цифр',
          min: (field, num) => `Телефон не має містити менше ${num} цифр`,
          max: (field, num) => `Телефон не має містити більше ${num} цифр`
        },
        address_text: {
          required: () => 'Адреса обов’язкова для заповнення',
          min: (field, num) => `Адрес не повинна бути менше ${num} символів`,
          max: (field, num) => `Адрес не повинна бути більше ${num} символів`
        },
        city: {
          required: () => 'Назва міста обов’язкова для заповнення',
          min: (field, num) => `Назва міста не повинна бути менше ${num} символів`,
          max: (field, num) => `Назва міста повинна бути більше ${num} символів`
        },
        postcode: {
          required: () => 'Поштовий індекс обов’язковий для заповнення',
          min: (field, num) => `Поштовий індекс не повинен бути менше ${num} символів`,
          max: (field, num) => `Поштовий індекс не повинен бути більше ${num} символів`
        },
        comment: {
          max: (field, num) => `Коментарій до замовлення не повинен бути більше ${num} символів`
        }
      }
      // messages: {
      //   required: () => 'Поле "Ім\'я" обов’язкове для заповнення'
      // }
    }
  }
}
