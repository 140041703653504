import Vue from 'vue'

export default {
  getAll() {
    return Vue.http.get(`/api/local/v1/addresses`)
              .then((response) => Promise.resolve(response.data))
              .catch((error) => Promise.reject(error))
  },

  add(data) {
    const params = { address: data }
    return Vue.http.post(`/api/local/v1/addresses`, params)
              .then((response) => Promise.resolve(response.data))
              .catch((error) => Promise.reject(error))
  },

  remove(address_id) {
    return Vue.http.delete(`/api/local/v1/addresses/${address_id}`)
              .then((response) => Promise.resolve(response.data))
              .catch((error) => Promise.reject(error))
  }
}
