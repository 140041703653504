import Vue from 'vue'

export default {
  getAllProducts() {
    return Vue.http.get(`/api/local/v1/carts/products`)
              .then((response) => Promise.resolve(response.data))
              .catch((error) => Promise.reject(error))
  },

  empty() {
    return Vue.http.delete(`/api/local/v1/carts/empty`)
              .then((response) => Promise.resolve(response.data))
              .catch((error) => Promise.reject(error))
  }
}
