<template lang="html">
  <div v-if="!product.combinations_mode">
    <button type="button" @click="addToCart" class="btn btn-outline-black" v-if="!local.flags.in_cart">
      <i class="fa fa-cart-plus" aria-hidden="true"></i>
      В кошик
    </button>

    <a :href="`/cart/overview`" class="btn btn-outline-success" v-else>
      <i class="fa fa-shopping-cart" aria-hidden="true"></i>
      В кошику
    </a>
  </div>

  <div v-else>
    <a :href="`/products/${product.url_path}`" class="small text-danger">
      Немає в наявності
    </a>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    discount: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      local: {
        form: {
          quantity: 1
        },
        flags: {
          in_cart: false
        }
      }
    }
  },

  computed: mapGetters({
    products: 'carts/products'
  }),

  watch: {
    products() {
      this.checkingCart()
    }
  },

  created() {
  },

  methods: {
    ...mapActions({
      getAllProducts: 'carts/getAllProducts'
    }),

    addToCart() {
      this.$store.dispatch('products/addToCart', [this.product.id, this.local.form.quantity]).then(() => {
        this.$noty.success(`Товар був успішно доданий до вашого кошика`)
        this.getAllProducts()
      }).catch((errors) => {
        this.$noty.error(`Товар не був доданий до кошика`)
        this.$noty.error(errors)
        console.error(errors)
      })
    },

    // removeFromCart() {
    //   this.$store.dispatch('products/removeFromCart', this.product.id).then(() => {
    //     this.$noty.success(`Товар був успішно доданий до вашого кошика`)
    //     this.getAllProducts()
    //   }).catch((errors) => {
    //     this.$noty.error(`Товар не був видалений з кошика`)
    //     this.$noty.error(errors)
    //     console.error(errors)
    //   })
    // },

    checkingCart() {
      if (_.isEmpty(this.products)) {
        return this.local.flags.in_cart = false
      }
      if (_.includes(_.map(this.products, 'id'), this.product.id)) {
        this.local.flags.in_cart = true
      } else {
        this.local.flags.in_cart = false
      }
    }
  },

  components: {
  }
}
</script>
