import Vue from 'vue'

import AddressesApi from 'api/v1/public/addresses'

const state = {
  lists: {
    all: []
  },

  loadings: {
    all: true
  }
}

const getters = {
  all: state => state.lists.all,

  loadings: state => state.loadings
}

const actions = {
  getAll({ commit }) {
    this.dispatch('addresses/setLoading', ['all'])
    return AddressesApi.getAll()
                       .then((response) => {
                         commit('GET_ALL', response)
                         return Promise.resolve(response)
                       })
                       .catch((error) => {
                         console.log(error)
                         return Promise.reject(error)
                       })
  },

  add({ commit }, data) {
    return AddressesApi.add(data)
                       .then((response) => {
                         commit('ADD', response)
                         return Promise.resolve(response)
                       })
                       .catch((error) => {
                         console.log(error)
                         return Promise.reject(error)
                       })
  },

  remove({ commit }, address_id) {
    return AddressesApi.remove(address_id)
                        .then((response) => {
                          commit('REMOVE', response)
                          return Promise.resolve(response)
                        })
                        .catch((error) => {
                          console.log(error)
                          return Promise.reject(error)
                        })
  },

  setLoading({ commit }, sections) {
    commit('SET_LOADING_FLAG', sections)
  },

  unsetLoading({ commit }, sections) {
    commit('UNSET_LOADING_FLAG', sections)
  }
}

const mutations = {
  ['GET_ALL'] (state, response) {
    Vue.set(state.lists, 'all', response)
    this.dispatch('addresses/unsetLoading', ['all'])
  },

  ['ADD'] (state, response) {
    this.dispatch('addresses/getAll')
  },

  ['REMOVE'] (state, response) {
    this.dispatch('addresses/getAll')
  },

  ['SET_LOADING_FLAG'] (state, sections) {
    _.set(state.loadings, _.join(sections, '.'), true)
  },

  ['UNSET_LOADING_FLAG'] (state, sections) {
    _.set(state.loadings, _.join(sections, '.'), false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
