<template lang="html">
  <div class="col-lg-4">
    <div :class="{ 'card': true, 'border-primary': $parent.$parent.local.form.address_id == address.id }">
      <div class="card-body">
        <div class="h5 mb-0">
          {{address.title}}
        </div>
      </div>

      <div class="card-body pt-0" v-if="!local.flags.remove">
        <ul class="list-unstyled mb-0">
          <li>
            {{address.first_name}}
            {{address.last_name}}
            {{address.middle_name}}
          </li>
          <li>
            {{address.country.title}},
            {{address.city}},
            {{address.address}}
          </li>
          <li>
            Почтовое отделение №{{address.postcode}}
          </li>
          <li>
            <i class="fa fa-envelope-o fa-fw" aria-hidden="true"></i>
            {{address.email}}
          </li>
          <li>
            <i class="fa fa-phone fa-fw" aria-hidden="true"></i>
            {{address.phone}}
          </li>
          <li class="mt-3">
            <button type="button" @click="$parent.$parent.selectAddress(address.id)" class="btn btn-outline-primary btn-sm" v-if="$parent.$parent.local.form.address_id != address.id">
              Вибрати
            </button>
            <!-- <button type="button" class="btn btn-outline-black btn-sm">
              Редагувати
            </button> -->
            <button type="button" @click="removeAddress" class="btn btn-outline-danger btn-sm">
              <i class="fa fa-trash-o" aria-hidden="true"></i>
            </button>
          </li>
        </ul>
      </div>

      <div class="card-body pt-0" v-else>
        <ul class="list-unstyled mb-0">
          <li class="mt-3">
            <button type="button" @click="removeAddressYes" class="btn btn-outline-danger btn-sm">
              Так видалити
            </button>
            <button type="button" @click="removeAddressNo" class="btn btn-outline-black btn-sm">
              Відміна
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      local: {
        flags: {
          remove: false
        }
      }
    }
  },

  computed: {
  },

  created() {
  },

  methods: {
    removeAddress() {
      this.local.flags.remove = true
      this.$parent.$parent.selectAddress(0)
    },

    removeAddressNo() {
      this.local.flags.remove = false
    },

    removeAddressYes() {
      event.preventDefault()
      event.target.disabled = true
      this.$el.style.opacity = 0.3
      this.$el.style.pointerEvents = 'none'

      this.$store.dispatch('addresses/remove', this.address.id).then(() => {
        this.$noty.success('Адрес был успешно удален')
      }).catch((errors) => {
        console.error(errors)
        this.$noty.error('Адрес не был удален')
      })
    }
  }
}
</script>
