import 'vuejs-noty/dist/vuejs-noty.css'

import Vue from 'vue'

import VueI18n from 'vue-i18n'
import VueResource from 'vue-resource'
import VeeValidate from 'vee-validate'
import Lodash from 'lodash'
import Noty from 'vuejs-noty'

import messages from 'messages/public'
import store from 'stores/public'

import NavigationCart from '@components/public/carts/navigation_cart'
import AddToCart from '@components/public/products/add_to_cart'
import AddToCartWithCombinations from '@components/public/products/add_to_cart_with_combinations'
import AddToCartLight from '@components/public/products/add_to_cart_light'

import Order from '@components/public/orders/main'

import NavigationSearch from '@components/public/search/base'

Vue.use(VueI18n)
Vue.use(VueResource)
Vue.use(VeeValidate, { locale: 'ua', dictionary: { ua: messages.ua.vee_validate } })
Vue.use(Lodash)
Vue.use(Noty, { layout: 'topRight', timeout: 2000 })

Vue.http.headers.common['X-CSRF-Token'] = document.head.querySelector('[name="csrf-token"]').content

const i18n = new VueI18n({
  locale: 'ua',
  messages
})

const app = new Vue({
  el: 'div#app',
  i18n,
  store,
  components: {
    NavigationCart,
    AddToCart,
    AddToCartWithCombinations,
    AddToCartLight,

    Order,

    NavigationSearch
  }
})
