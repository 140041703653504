import Vue from 'vue'

export default {
  getAllCombinations(product_id) {
    return Vue.http.get(`/api/local/v1/products/${product_id}/combinations`)
              .then((response) => Promise.resolve(response.data))
              .catch((error) => Promise.reject(error))
  },

  addToCart(product_id, quantity, price = null, combinations = null) {
    const params = {
      quantity: quantity,
      price: price,
      combinations: combinations
    }
    return Vue.http.post(`/api/local/v1/products/${product_id}/add_to_cart`, params)
              .then((response) => Promise.resolve(response.data))
              .catch((error) => Promise.reject(error))
  },

  removeFromCart(product_id) {
    return Vue.http.delete(`/api/local/v1/products/${product_id}/remove_from_cart`)
              .then((response) => Promise.resolve(response.data))
              .catch((error) => Promise.reject(error))
  }
}
