<template lang="html">
  <div>
    <!-- <div>
      {{loadings.all}}
    </div> -->
    <!-- <div>
      {{addresses.length}}
    </div> -->

    <div class="row" v-if="!local.flags.add && !loadings.all && addresses.length > 0">
      <address-item v-for="address in addresses" :key="address.id" :address="address"></address-item>
    </div>

    <new-address :user="user" :is_guest="is_guest" v-if="local.flags.add || (!loadings.all && addresses.length == 0)"></new-address>

    <div class="mt-3">
      <button type="button" @click="newAddress" class="btn btn-outline-success" v-if="!local.flags.add && addresses.length > 0">
        Нова адреса
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import AddressItem from '@components/public/orders/addresses/item'
import NewAddress from '@components/public/orders/addresses/new'

export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    is_guest: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      local: {
        flags: {
          add: false,
          edit: false
        }
      }
    }
  },

  computed: mapGetters({
    addresses: 'addresses/all',
    countries: 'countries/all',

    loadings: 'addresses/loadings'
  }),

  created() {
    this.getAllAddresses()
    this.getAllCountries()
  },

  methods: {
    ...mapActions({
      getAllAddresses: 'addresses/getAll',
      getAllCountries: 'countries/getAll'
    }),

    newAddress() {
      this.local.flags.add = true
    },

    newAddressCancel() {
      this.local.flags.add = false
    }
  },

  components: {
    AddressItem,
    NewAddress
  }
}
</script>
