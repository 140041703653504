import Vue from 'vue'

import CartsApi from 'api/v1/public/carts'

const state = {
  lists: {
    products: []
  },

  loadings: {
    products: true
  }
}

const getters = {
  products: state => state.lists.products,

  loadings: state => state.loadings
}

const actions = {
  getAllProducts({ commit }) {
    this.dispatch('carts/setLoading', ['products'])
    return CartsApi.getAllProducts()
                   .then((response) => commit('GET_ALL_PRODUCTS', response))
                   .catch((error) => console.log(error))
  },

  empty({ commit }) {
    return CartsApi.empty()
                   .then((response) => commit('EMPTY', response))
                   .catch((error) => console.log(error))
  },

  setLoading({ commit }, sections) {
    commit('SET_LOADING_FLAG', sections)
  },

  unsetLoading({ commit }, sections) {
    commit('UNSET_LOADING_FLAG', sections)
  }
}

const mutations = {
  ['GET_ALL_PRODUCTS'] (state, response) {
    Vue.set(state.lists, 'products', response)
    this.dispatch('carts/unsetLoading', ['products'])
  },

  ['EMPTY'] (state, response) {
  },

  ['SET_LOADING_FLAG'] (state, sections) {
    _.set(state.loadings, _.join(sections, '.'), true)
  },

  ['UNSET_LOADING_FLAG'] (state, sections) {
    _.set(state.loadings, _.join(sections, '.'), false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
