import Vue from 'vue'

export default {
  getAllProducts(order_id) {
    return Vue.http.get(`/api/local/v1/orders/${order_id}/products`)
              .then((response) => Promise.resolve(response.data))
              .catch((error) => Promise.reject(error))
  },

  build(order_id, data) {
    if (data.address_id == 0) {
      return this.$noty.error('Пожалуйста, выберите адрес доставки')
    }
    if (data.carrier_id == 0) {
      return this.$noty.error('Пожалуйста, выберите способ доставки')
    }
    if (data.payment_id == 0) {
      return this.$noty.error('Пожалуйста, выберите способ оплаты')
    }
    const params = { order: data }
    return Vue.http.post(`/api/local/v1/orders/${order_id}/build`, params)
              .then((response) => Promise.resolve(response.data))
              .catch((error) => Promise.reject(error))
  },
}
