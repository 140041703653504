<template lang="html">
  <div class="mb-4">

    <!-- {{products}} -->

    <div class="d-flex flex-wrap mb-2" v-if="!local.flags.in_cart">
      <div class="align-self-center mr-3">
        <button type="button" @click="addToCart" class="btn btn-outline-success">
          <i class="fa fa-cart-plus" aria-hidden="true"></i>
          В кошик
        </button>
      </div>

      <div class="align-self-center mr-5">
        <div class="input-group">
          <div class="input-group-prepend">
            <button type="button" @click="decrement" class="btn btn-outline-black">
              -
            </button>
          </div>

          <span class="input-group-text bg-transparent border-black text-black">
            {{local.form.quantity}}
          </span>

          <div class="input-group-append">
            <button type="button" @click="increment" class="btn btn-outline-black">
              +
            </button>
          </div>
        </div>
      </div>

      <div class="align-self-center mr-4" v-if="discount">
        <div class="h3 mb-0 text-muted">
          <strike>
            {{product.price}}
            {{current_currency.symbol}}
          </strike>
        </div>

        <div class="small text-muted">
          Скидка {{discount.discount_percent}}%
        </div>
      </div>

      <div class="align-self-center mr-4" v-if="local.form.quantity > 1">
        <div class="h3 mb-0">
          {{(local.form.quantity * price).toFixed(2)}}
          {{current_currency.symbol}}
        </div>

        <div class="small text-muted">
          Ціна за {{local.form.quantity}} шт.
        </div>
      </div>

      <div class="align-self-center">
        <div class="h3 mb-0">
          {{price}}
          {{current_currency.symbol}}
        </div>

        <div class="small text-muted" v-if="local.form.quantity > 1">
          Ціна за шт.
        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap mb-2" v-else>
      <div class="align-self-center mr-5">
        <div class="h3 text-success mb-0">
          В кошику
        </div>

        <div class="small text-muted">
          <a @click="removeFromCart" href="javascript:void(0)" class="text-muted">
            Видалити
          </a>
        </div>
      </div>

      <div class="align-self-center mr-4" v-if="discount">
        <div class="h3 mb-0 text-muted">
          <strike>
            {{product.price}}
            {{current_currency.symbol}}
          </strike>
        </div>

        <div class="small text-muted">
          Скидка {{discount.discount_percent}}%
        </div>
      </div>

      <div class="align-self-center">
        <div class="h3 mb-0">
          {{price}}
          {{current_currency.symbol}}
        </div>
      </div>
    </div>

    <div class="small text-muted">
      В наявності: {{product.quantity}}
    </div>

    <!-- <div class="small text-muted" v-if="product.combinations_mode">
      Включен режим комбинаций
    </div> -->

    <!-- <div class="small text-muted" v-else>
      В наявності: {{product.quantity}}
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    discount: {
      type: Object,
      required: false
    },
    current_currency: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      local: {
        form: {
          quantity: 1
        },
        flags: {
          in_cart: false
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      products: 'carts/products'
    }),

    discount_price() {
      if (!_.isNil(this.discount)) {
        return (this.product.price * this.discount.discount_percent / 100.0).toFixed(2)
      }
      return 0
    },

    price() {
      if (!_.isNil(this.discount)) {
        return (this.product.price - this.discount_price).toFixed(2)
      }
      return this.product.price
    }
  },

  watch: {
    products() {
      this.checkingCart()
    }
  },

  created() {
  },

  methods: {
    ...mapActions({
      getAllProducts: 'carts/getAllProducts'
    }),

    addToCart() {
      this.$store.dispatch('products/addToCart', [this.product.id, this.local.form.quantity]).then(() => {
        this.$noty.success(`Товар був успішно доданий до вашого кошика`)
        this.getAllProducts()
      }).catch((errors) => {
        this.$noty.error(`Товар не був доданий до кошика`)
        this.$noty.error(errors)
        console.error(errors)
      })
    },

    removeFromCart() {
      this.$store.dispatch('products/removeFromCart', this.product.id).then(() => {
        this.$noty.success(`Товар був успішно доданий до вашого кошика`)
        this.getAllProducts()
      }).catch((errors) => {
        this.$noty.error(`Товар не був видалений з кошика`)
        this.$noty.error(errors)
        console.error(errors)
      })
    },

    checkingCart() {
      if (_.isEmpty(this.products)) {
        return this.local.flags.in_cart = false
      }
      if (_.includes(_.map(this.products, 'id'), this.product.id)) {
        this.local.flags.in_cart = true
      } else {
        this.local.flags.in_cart = false
      }
    },

    increment() {
      if (this.product.combinations_mode) {
        return
      } else {
        if (this.local.form.quantity == this.product.quantity) {
          return
        }
      }

      this.local.form.quantity += 1
    },

    decrement() {
      if (this.local.form.quantity == 1) {
        return
      }
      this.local.form.quantity -= 1
    }
  }
}
</script>
