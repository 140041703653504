<template lang="html">
  <div class="mb-4">

    <!-- {{local.combinations.source}} -->

    <!-- {{this.local.combinations.selected}} -->

    <div class="d-flex flex-wrap mb-2" v-if="!local.flags.in_cart">
      <div class="align-self-center mr-3 mb-3">
        <button type="button" @click="addToCart" :disabled="canAddToCart()" class="btn btn-outline-success">
          <i class="fa fa-cart-plus" aria-hidden="true"></i>
          В кошик
        </button>
      </div>

      <div class="align-self-center mr-5 mb-3">
        <div class="input-group">
          <div class="input-group-prepend">
            <button type="button" @click="decrement" :disabled="canAddToCart()" class="btn btn-outline-black">
              -
            </button>
          </div>

          <span class="input-group-text bg-transparent border-black text-black">
            {{local.form.quantity}}
          </span>

          <div class="input-group-append">
            <button type="button" @click="increment" :disabled="canAddToCart()" class="btn btn-outline-black">
              +
            </button>
          </div>
        </div>
      </div>

      <div class="align-self-center mr-4 mb-3" v-if="local.form.quantity > 1">
        <div class="h3 mb-0">
          {{(local.form.quantity * product.price).toFixed(2)}}
          {{current_currency.symbol}}
        </div>

        <div class="small text-muted text-right">
          Ціна за {{local.form.quantity}} шт.
        </div>
      </div>

      <div class="align-self-center mb-3">
        <div class="h3 mb-0">
          {{(product.price + local.additional_price).toFixed(2)}}
          {{current_currency.symbol}}
        </div>

        <div class="small text-muted text-right" v-if="local.form.quantity > 1">
          Ціна за шт.
        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap mb-2" v-if="local.flags.in_cart">
      <div class="align-self-center mr-5">
        <div class="h3 text-success mb-0">
          В кошику
        </div>

        <div class="small text-muted">
          <a @click="removeFromCart" href="javascript:void(0)" class="text-muted">
            Видалити
          </a>
        </div>
      </div>

      <div class="align-self-center">
        <div class="h3 mb-0">
          {{product.price}}
          {{current_currency.symbol}}
        </div>
      </div>
    </div>

    <div class="small text-muted mb-3" v-if="!local.flags.in_cart">
      В наявності: {{local.max_quantity}}
    </div>

    <div class="card border-0 bg-transparent" v-if="!local.flags.in_cart">
      <table class="table mb-0">
        <tbody>
          <tr v-for="combination in local.combinations.source" :key="combination.id">
            <td class="align-middle pl-0">
              {{combination.title}}
            </td>
            <td class="align-middle">
              <div class="d-flex align-content-start flex-wrap">
                <button v-for="value in combination.values" @click="toggleCombination(combination, value)" type="button" :disabled="value.quantity == 0" :class="`btn btn${isSelectedCombination(value) ? '' : '-outline'}-${value.quantity == 0 ? 'secondary' : 'black'} btn-sm mr-2`">
                  {{value.value}}
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// import Combinations from '@components/public/products/combinations/list'

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    current_currency: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      local: {
        combinations: {
          source: this.combinations,
          selected: []
        },
        form: {
          quantity: 1
        },
        flags: {
          in_cart: false
        },

        additional_price: 0,
        max_quantity: 0
      }
    }
  },

  computed: mapGetters({
    combinations: 'products/combinations',
    products: 'carts/products'
  }),

  watch: {
    combinations() {
      this.preparationCombinations()
    },

    products() {
      this.checkingCart()
    }
  },

  created() {
    this.getAllCombinations(this.product.id)
  },

  methods: {
    ...mapActions({
      getAllCombinations: 'products/getAllCombinations',
      getAllProducts: 'carts/getAllProducts'
    }),

    addToCart() {
      this.$store.dispatch('products/addToCart', [this.product.id,
                                                  this.local.form.quantity,
                                                  this.product.price + this.local.additional_price,
                                                  this.local.combinations.selected]).then(() => {
        this.$noty.success(`Товар був успішно доданий до вашого кошика`)
        this.getAllProducts()
      }).catch((errors) => {
        this.$noty.error(`Товар не був доданий до кошика`)
        this.$noty.error(errors)
        console.error(errors)
      })
    },

    removeFromCart() {
      this.$store.dispatch('products/removeFromCart', this.product.id).then(() => {
        this.$noty.success(`Товар був успішно доданий до вашого кошика`)
        this.getAllProducts()
      }).catch((errors) => {
        this.$noty.error(`Товар не був видалений з кошика`)
        this.$noty.error(errors)
        console.error(errors)
      })
    },

    preparationCombinations() {
      if (_.isEmpty(this.combinations)) {
        return
      }
      this.local.combinations.source = this.combinations

      _.forEach(this.combinations, (combination) => {
        _.forEach(combination.values, (value) => {
          if (this.local.max_quantity < value.quantity) {
            this.local.max_quantity = value.quantity
          }
        })
      })
    },

    canAddToCart() {
      return !(_.size(this.local.combinations.selected) == _.size(this.local.combinations.source))
    },

    toggleCombination(combination, value) {
      const index = _.findIndex(this.local.combinations.selected, (o) => {
        const key = _.head(_.keys(o))

        return key == value.attribute_id
      })

      let hash = {}
      hash[value.attribute_id] = {
        id: value.attributes_value_id,
        price: value.price,
        quantity: value.quantity
      }

      if (index >= 0) {
        this.$set(this.local.combinations.selected, index, hash)
      } else {
        this.local.combinations.selected = _.concat(this.local.combinations.selected, hash)
      }

      ///////////////////////////////

      let min = 0
      let price = 0.0

      _.forEach(this.local.combinations.selected, (combination) => {
        const key = _.head(_.keys(combination))

        if (min == 0 || min > combination[key].quantity) {
          min = combination[key].quantity
        }

        price += combination[key].price
      })

      this.local.additional_price = price
      this.local.max_quantity = min

      if (this.local.form.quantity > this.local.max_quantity) {
        this.local.form.quantity = this.local.max_quantity
      }
    },

    isSelectedCombination(value) {
      if (_.isNil(this.local.combinations.selected)) {
        return false
      }

      const index = _.findIndex(this.local.combinations.selected, (o) => {
        const key = _.head(_.keys(o))

        return key == value.attribute_id
      })

      if (index < 0) {
        return false
      }

      return this.local.combinations.selected[index][value.attribute_id].id == value.attributes_value_id
             ? true
             : false
    },

    checkingCart() {
      if (_.isEmpty(this.products)) {
        return this.local.flags.in_cart = false
      }
      if (_.includes(_.map(this.products, 'id'), this.product.id)) {
        this.local.flags.in_cart = true
      } else {
        this.local.flags.in_cart = false
      }
    },

    increment() {
      if (this.product.combinations_mode) {
        if (this.local.form.quantity == this.local.max_quantity) {
          return
        }
      } else {
        return
      }

      this.local.form.quantity += 1
    },

    decrement() {
      if (this.local.form.quantity == 1) {
        return
      }
      this.local.form.quantity -= 1
    }
  },

  components: {
  }
}
</script>
