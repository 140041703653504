<template lang="html">
  <div>
    <div class="card card-shadow border-0 mb-4">
      <div class="card-body">
        <div class="h4 mb-0">
          Виберіть спосіб доставки
        </div>
      </div>

      <div class="card-body pt-0">
        <div class="alert alert-warning" role="alert">
          Будь ласка, переконайтеся, що вказали правильний номер поштового відділення (індекс) в адресі доставки.
        </div>

        <div class="list-group">
          <a v-for="carrier in carriers" :key="carrier.id" @click="selectCarrier(carrier.id)" href="javascript:void(0)" :class="{ 'list-group-item list-group-item-action flex-column align-items-start': true, 'active': local.form.carrier_id == carrier.id }">
            <div class="d-flex w-100 justify-content-between">
              <div class="h5 mb-1">
                {{carrier.title}}
              </div>
              <ul class="list-inline small text-muted">
                <li class="list-inline-item" v-if="carrier.free_delivery && !carrier.payment_upon_receipt">
                  Безкоштовна доставка
                </li>
                <li :class="['list-inline-item', local.form.carrier_id == carrier.id ? 'text-white' : 'text-danger']" v-if="carrier.payment_upon_receipt">
                  Оплата при отриманні
                </li>
              </ul>
            </div>
            <div class="mb-1">
              <div class="d-flex justify-content-between">
                <div class="align-self-center">
                  {{carrier.description}}
                </div>

                <div class="align-self-center font-weight-bold text-nowrap ml-5">
                  <span v-if="carrier.free_delivery">
                    0
                  </span>
                  {{current_currency.symbol}}
                </div>
              </div>

            </div>
            <small :class="[local.form.carrier_id == carrier.id ? 'text-white' : 'text-muted']">
              Терміни доставки: {{carrier.delivery_time}}
            </small>
          </a>
        </div>
      </div>
    </div>

    <div class="card card-shadow border-0 mb-4">
      <div class="card-body">
        <div class="h4 mb-0">
          Виберіть адресу доставки
        </div>
      </div>

      <div class="card-body pt-0">
        <addresses-list :user="user" :is_guest="is_guest"></addresses-list>
      </div>
    </div>

    <div class="card card-shadow border-0 mb-4">
      <div class="card-body">
        <div class="h4 mb-0">
          Виберіть спосіб оплати
        </div>
      </div>

      <div class="card-body pt-0">
        <div class="list-group">
          <a v-for="payment in payments" :key="payment.id" @click="selectPayment(payment.id)" href="javascript:void(0)" :class="{ 'list-group-item list-group-item-action flex-column align-items-start': true, 'active': local.form.payment_id == payment.id }">
            <div class="h5 mb-1">
              {{payment.title}}
            </div>
            <div class="mb-1">
              {{payment.description}}
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="card card-shadow border-0 mb-4">
      <div class="card-body">
        <div class="h4 mb-0">
          Перелік товарів
        </div>
      </div>

      <table class="table">
        <thead>
          <tr>
            <th scope="col">
              <!--  -->
            </th>
            <th scope="col">
              Назва
            </th>
            <th scope="col">
              Ціна за од.
            </th>
            <th scope="col">
              К-сть
            </th>
            <th class="text-right" scope="col">
              Ціна
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="product in products.list" :key="product.id">
            <td class="align-middle">
              <!--  -->
            </td>
            <td class="align-middle">
              <div class="font-weight-bold">
                <a :href="`/products/${product.url_path}`" class="text-dark" target="_blank">
                  {{product.title}}
                </a>
              </div>
              <div class="text-muted">
                {{product.vendor_code}}
              </div>
            </td>
            <td class="align-middle">
              {{product.unit_price}}
            </td>
            <td class="align-middle">
              {{product.quantity}}
            </td>
            <td class="align-middle text-right">
              {{product.total_price}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="card card-shadow border-0 mb-4">
      <div class="card-body">
        <div class="form-group">
          <label :for="`textareaComment`">Коментар до замовлення</label>
          <textarea name="comment" v-model.trim="local.form.comment" v-validate.initial="'max:300'" :class="['form-control', errors.has('comment') ? 'is-invalid' : 'is-valid']" :id="`textareaComment`" rows="2" placeholder="Якщо ви маєте будь-які коментарі чи побажання до замовлення, будь-ласка, запишіть їх тут."></textarea>
          <div class="invalid-feedback" v-if="errors.has('comment')">
            {{errors.first('comment')}}
          </div>
          <!-- <div class="valid-feedback" v-else>
            Заповнено правильно
          </div> -->
        </div>

        <div class="form-check">
          <input
            v-model="local.form.call_for_confirmation"
            class="form-check-input"
            type="checkbox"
            id="callForConfirmation">

          <label class="form-check-label" for="callForConfirmation">
            Зателефонуйте мені для уточнення деталей
          </label>
        </div>
      </div>

      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="align-self-center">
            <button type="button" @click="buildOrder" :disabled="local.form.address_id == 0 || local.form.carrier_id == 0 || local.form.payment_id == 0" class="btn btn-success btn-lg">
              Замовити
            </button>

            <div class="mt-2">
              Оформивши замовлення, ви погоджуєтесь з <a href="/pages/payment-delivery" target="_blank">умовами розміщення замовлення, оплати та доставки</a>.
            </div>

            <ul class="small text-danger list-unstyled mt-3 mb-0">
              <li v-if="local.form.address_id == 0">
                Будь ласка, виберіть адресу доставки
              </li>
              <li v-if="local.form.carrier_id == 0">
                Будь ласка, виберіть спосіб доставки
              </li>
              <li v-if="local.form.payment_id == 0">
                Будь ласка, виберіть спосіб оплати
              </li>
            </ul>
          </div>

          <div class="align-self-start">
            <div class="lead text-uppercase font-weight-bold">
              До оплати:
              {{products.total_price}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import AddressesList from '@components/public/orders/addresses/list'

export default {
  props: {
    order: {
      type: Object,
      required: true
    },
    current_currency: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    is_guest: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      local: {
        form: {
          address_id: 0,
          carrier_id: 0,
          payment_id: 0,
          comment: '',
          call_for_confirmation: false
        }
      }
    }
  },

  computed: mapGetters({
    carriers: 'carriers/all',
    payments: 'payments/all',
    products: 'orders/products',

    carrier_loadings: 'carriers/loadings',
    payment_loadings: 'payments/loadings',
    products_loadings: 'orders/loadings'
  }),

  created() {
    this.getAllCarriers()
    this.getAllPayments()
    this.getAllProducts(this.order.id)
  },

  methods: {
    ...mapActions({
      getAllCarriers: 'carriers/getAll',
      getAllPayments: 'payments/getAll',
      getAllProducts: 'orders/getAllProducts'
    }),

    selectAddress(address_id) {
      this.local.form.address_id = address_id
    },

    selectCarrier(carrier_id) {
      this.local.form.carrier_id = carrier_id
    },

    selectPayment(payment_id) {
      this.local.form.payment_id = payment_id
    },

    buildOrder() {
      if (this.local.form.address_id == 0) {
        return this.$noty.error('Будь ласка, виберіть адресу доставки')
      }
      if (this.local.form.carrier_id == 0) {
        return this.$noty.error('Будь ласка, виберіть спосіб доставки')
      }
      if (this.local.form.payment_id == 0) {
        return this.$noty.error('Будь ласка, виберіть спосіб оплати')
      }

      this.$store.dispatch('orders/build', [this.order.id, this.local.form]).then(() => {
        this.$noty.success('Замовлення успішно оформлено')
        window.location = `${window.location.href}/overview`
      }).catch((errors) => {
        console.error(errors)
        this.$noty.error('Замовлення не було оформлене. Будь ласка, спробуйте ще раз або повідомте адміністрації про помилку.')
      })
    }
  },

  components: {
    AddressesList
  }
}
</script>
