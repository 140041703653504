import SingleChangeApi from 'api/v1/public/single_change'

const state = {
}

const getters = {
}

const actions = {
  simple({ commit }, [resource, id, field, value]) {
    return SingleChangeApi.simple(resource, id, field, value)
  },

  form_data({ commit }, [resource, id, field, value]) {
    return SingleChangeApi.form_data(resource, id, field, value)
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
