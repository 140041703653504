<template>
  <div>
    <li class="nav-item dropdown dropdown-search">
      <a href="javascript:void(0)" data-toggle="modal" data-target="#mainSearchOnTheSTModal" class="nav-link">
        <i class="fa fa-search" aria-hidden="true"></i>
        Пошук
      </a>
    </li>

    <div class="modal fade" id="mainSearchOnTheSTModal" tabindex="-1" role="dialog" aria-labelledby="mainSearchOnTheSTModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content border-0">
          <div class="modal-header">
            <div class="h5 modal-title" id="mainSearchOnTheSTModalLabel">
              Пошук по сайту
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <div class="input-group">
              <input v-model.trim="form.query" @keyup="search" type="text" class="form-control" placeholder="Введіть ключові слова для пошуку">

              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fa fa-search" aria-hidden="true" v-if="!loadings.products.started && !loadings.products.results"></i>
                  <i class="fa fa-refresh fa-spin" aria-hidden="true" v-else-if="loadings.products.started && loadings.products.results"></i>
                  <i class="fa fa-check-circle-o" aria-hidden="true" v-else-if="loadings.products.started && !loadings.products.results"></i>
                </span>
              </div>

              <div class="input-group-append">
                <button @click="search" :disabled="loadings.products.results" class="btn btn-primary" type="button">
                  Шукати
                </button>
              </div>
            </div>

            <div class="small text-muted mt-2 mb-2">
              <span key="if_total_size" v-if="!loadings.products.started">
                Ви ще нічого не шукали
              </span>
              <span key="if_total_size" v-else-if="loadings.products.started && loadings.products.results">
                Триває пошук
              </span>
              <span key="if_total_size" v-else>
                Знайдено: {{products.total}}
              </span>

              <span class="text-danger" key="if_minimum_length" v-if="form.query.length < 3">
                (мінімум 3 символи)
              </span>
            </div>

            <products :current_currency="current_currency"></products>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Products from './products'

export default {
  props: {
    current_currency: {
      type: Object,
      required: true
    }
  },

  computed: mapGetters({
    products: 'search/products',
    loadings: 'search/loadings'
  }),

  data() {
    return {
      form: {
        query: ''
      }
    }
  },

  methods: {
    search() {
      if (_.isNil(this.form.query) || _.isEmpty(this.form.query) || _.size(this.form.query) < 3) {
        return console.info('The query is not specified or less than 3 characters')
      }
      this.searchSend()
    },

    searchSend: _.debounce(function() {
      this.$store.dispatch('search/products', this.form.query)
    }, 500)
  },

  components: {
    Products
  }
}
</script>
