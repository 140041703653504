<template lang="html">
  <form @submit.prevent="validateForm" autocomplete="off">
    <!-- {{local.form.address}} -->

    <div class="form-group">
      <label :for="`inputFirstName`">Назва адреси</label>
      <input type="text" name="title" v-model.trim="local.form.address.title" v-validate.initial="'required|min:2|max:100'" :class="['form-control', errors.has('title') ? 'is-invalid' : 'is-valid']" :id="`inputFirstName`" placeholder="" required>
      <div class="invalid-feedback" v-if="errors.has('title')">
        {{errors.first('title')}}
      </div>
      <div class="valid-feedback" v-else>
        Заповнено вірно
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-4">
        <label :for="`inputFirstName`">Ім'я</label>
        <input type="text" name="first_name" v-model.trim="local.form.address.first_name" v-validate.initial="'required|min:2|max:50'" :class="['form-control', errors.has('first_name') ? 'is-invalid' : 'is-valid']" :id="`inputFirstName`" placeholder="" required>
        <div class="invalid-feedback" v-if="errors.has('first_name')">
          {{errors.first('first_name')}}
        </div>
        <div class="valid-feedback" v-else>
          Заповнено вірно
        </div>
      </div>
      <div class="form-group col-md-4">
        <label :for="`inputLastName`">Прізвище</label>
        <input type="text" name="last_name" v-model.trim="local.form.address.last_name" v-validate.initial="'required|min:2|max:50'" :class="['form-control', errors.has('last_name') ? 'is-invalid' : 'is-valid']" :id="`inputLastName`" placeholder="" required>
        <div class="invalid-feedback" v-if="errors.has('last_name')">
          {{errors.first('last_name')}}
        </div>
        <div class="valid-feedback" v-else>
          Заповнено вірно
        </div>
      </div>
      <div class="form-group col-md-4">
        <label :for="`inputMiddleName`">По батькові</label>
        <input type="text" name="middle_name" v-model.trim="local.form.address.middle_name" v-validate.initial="'max:50'" :class="['form-control', errors.has('middle_name') ? 'is-invalid' : 'is-valid']" :id="`inputMiddleName`" placeholder="">
        <div class="invalid-feedback" v-if="errors.has('middle_name')">
          {{errors.first('middle_name')}}
        </div>
        <!-- <div class="valid-feedback" v-else>
          Заповнено вірно
        </div> -->
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-6">
        <label :for="`inputEmail`">E-mail</label>
        <input type="email" name="email" v-model.trim="local.form.address.email" v-validate.initial="'required|email'" :class="['form-control', errors.has('email') ? 'is-invalid' : 'is-valid']" :id="`inputEmail`" placeholder="" required v-if="is_guest">
        <input type="email" readonly class="form-control-plaintext" :id="`inputEmail`" :value="user.email" v-else>
        <div class="invalid-feedback" v-if="errors.has('email')">
          {{errors.first('email')}}
        </div>
        <div class="valid-feedback" v-else>
          Заповнено вірно
        </div>
      </div>
      <div class="form-group col-md-6">
        <label :for="`inputPhone`">Телефон</label>
        <input type="tel" name="phone" v-model.trim="local.form.address.phone" v-validate.initial="'required|numeric|min:12|max:12'" :class="['form-control', errors.has('phone') ? 'is-invalid' : 'is-valid']" :id="`inputPhone`" placeholder="" required>
        <div class="invalid-feedback" v-if="errors.has('phone')">
          {{errors.first('phone')}}
        </div>
        <div class="valid-feedback" v-else>
          Заповнено вірно
        </div>
      </div>
    </div>

    <div class="form-group">
      <label :for="`textareaAddress`">Адреса</label>
      <textarea name="address_text" v-model.trim="local.form.address.address_text" v-validate.initial="'max:500'" :class="['form-control', errors.has('address_text') ? 'is-invalid' : 'is-valid']" :id="`textareaAddress`" rows="2" placeholder="Вулиця, дім, квартира"></textarea>
      <div class="invalid-feedback" v-if="errors.has('address_text')">
        {{errors.first('address_text')}}
      </div>
      <div class="valid-feedback" v-else>
        Заповнено вірно
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-4">
        <label :for="`selectCountry`">Країна</label>
        <select v-model="local.form.address.country" class="custom-select" :id="`selectCountry`">
          <option v-for="country in countries" :key="country.id" :value="country">
            {{country.title}}
          </option>
        </select>
      </div>
      <div class="form-group col-md-4">
        <label :for="`inputCity`">Область, місто</label>
        <input type="text" name="city" v-model.trim="local.form.address.city" v-validate.initial="'required|min:2'" :class="['form-control', errors.has('city') ? 'is-invalid' : 'is-valid']" :id="`inputCity`" placeholder="">
        <div class="invalid-feedback" v-if="errors.has('city')">
          {{errors.first('city')}}
        </div>
        <div class="valid-feedback" v-else>
          Заповнено вірно
        </div>
      </div>
      <div class="form-group col-md-4">
        <label :for="`inputPostcode`">Номер поштового відділення (індекс)</label>
        <input type="text" name="postcode" v-model.trim="local.form.address.postcode" v-validate.initial="'required|min:1|max:5'" :class="['form-control', errors.has('postcode') ? 'is-invalid' : 'is-valid']" :id="`inputPostcode`" placeholder="">

        <small class="form-text text-muted">
          Для Нової Пошти - номер відділення або поштамату, для УкрПошти - індекс.
        </small>

        <div class="invalid-feedback" v-if="errors.has('postcode')">
          {{errors.first('postcode')}}
        </div>
        <div class="valid-feedback" v-else>
          Заповнено вірно
        </div>
      </div>
    </div>

    <div class="form-group">
      Всі поля обов'язкові для заповнення
    </div>

    <div class="form-group mb-0">
      <button type="submit" class="btn btn-success">
        Зберегти адресу
      </button>

      <button type="button" @click="$parent.newAddressCancel" class="btn btn-outline-black">
        Закрити
      </button>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    is_guest: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      local: {
        form: {
          address: {
            first_name: this.is_guest ? '' : this.user.first_name,
            last_name: this.is_guest ? '' : this.user.last_name,
            middle_name: this.is_guest ? '' : this.user.middle_name,
            email: '',
            phone: '',
            address_text: '',
            postcode: '',
            country: {
              id: 0,
              title: '',
              code: ''
            },
            city: '',
            title: 'Мій дім'
          }
        }
      }
    }
  },

  computed: mapGetters({
    countries: 'countries/all'
  }),

  watch: {
    ['countries'] (countries) {
      this.setCountry(_.first(this.countries))
    },
    ['local.form.address.country'] (newCountry) {
      this.setCountry(newCountry)
    }
  },

  created() {
    this.setCountry()
  },

  methods: {
    setCountry(country) {
      let firstCountry

      // console.log(this.countries);

      if (country == null) {
        firstCountry = _.first(this.countries)
      } else {
        firstCountry = country
      }

      if (_.isNil(firstCountry)) {
        return
      }

      this.local.form.address.country = firstCountry

      if (_.isEmpty(_.toString(this.local.form.address.phone))) {
        this.local.form.address.phone = firstCountry.code
      }
    },

    validateForm() {
      this.$validator.validateAll().then((result) => {
         if (!result) {
           return console.error('Validation error')
         }
         this.sendForm()
       }).catch((error) => {
         console.log(error)
       })
    },

    sendForm() {
      let nice_address = this.local.form.address
      const country_id = this.local.form.address.country.id
      _.unset(nice_address, 'country')
      _.set(nice_address, 'country_id', country_id)
      this.$store.dispatch('addresses/add', nice_address).then((response) => {
        this.$validator.reset()
        this.$parent.newAddressCancel()

        setTimeout(() => {
          this.$parent.$parent.selectAddress(response.id)
        }, 200)

        this.$noty.success('Адреса успішно додана')
      }).catch((errors) => {
        console.error(errors)
        this.$noty.error('Адрес не був доданий')
      })
    }
  },

  components: {
  }
}
</script>
