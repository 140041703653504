import Vue from 'vue'

export default {
  simple(resource, id, field, value) {
    if (_.isNil(resource) || _.isNil(id) ||
        _.isNil(field) || _.isUndefined(value)) {
      return console.error('Not all parameters have been specified')
    }
    const data = {
      resource: resource,
      id: id,
      field: field,
      value: value
    }
    return Vue.http.post(`/api/local/v1/single_change`, data)
              .then((response) => Promise.resolve(response.data))
              .catch((error) => Promise.reject(error))
  },

  form_data(resource, id, field, value) {
    if (_.isNil(resource) || _.isNil(id) ||
        _.isNil(field) || _.isUndefined(value)) {
      return console.error('Not all parameters have been specified')
    }
    let data = new FormData()
    data.append('resource', resource)
    data.append('id', id)
    data.append('field', field)
    data.append('value', value)
    return Vue.http.post(`/api/local/v2/single_change`, data)
              .then((response) => Promise.resolve(response.data))
              .catch((error) => Promise.reject(error))
  }
}
