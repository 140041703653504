import Vue from 'vue'

import OrdersApi from 'api/v1/public/orders'

const state = {
  lists: {
    products: []
  },

  loadings: {
    products: true
  }
}

const getters = {
  products: state => state.lists.products,

  loadings: state => state.loadings
}

const actions = {
  getAllProducts({ commit }, order_id) {
    this.dispatch('orders/setLoading', ['products'])
    return OrdersApi.getAllProducts(order_id)
                    .then((response) => {
                      commit('GET_ALL_PRODUCTS', response)
                      return Promise.resolve(response)
                    })
                    .catch((error) => {
                      console.log(error)
                      return Promise.reject(error)
                    })
  },

  build({ commit }, [order_id, data]) {
    return OrdersApi.build(order_id, data)
                    .then((response) => {
                      commit('BUILD', response)
                      return Promise.resolve(response)
                    })
                    .catch((error) => {
                      console.log(error)
                      return Promise.reject(error)
                    })
  },

  setLoading({ commit }, sections) {
    commit('SET_LOADING_FLAG', sections)
  },

  unsetLoading({ commit }, sections) {
    commit('UNSET_LOADING_FLAG', sections)
  }
}

const mutations = {
  ['GET_ALL_PRODUCTS'] (state, response) {
    Vue.set(state.lists, 'products', response)
    this.dispatch('orders/unsetLoading', ['products'])
  },

  ['BUILD'] (state, response) {
    //
  },

  ['SET_LOADING_FLAG'] (state, sections) {
    _.set(state.loadings, _.join(sections, '.'), true)
  },

  ['UNSET_LOADING_FLAG'] (state, sections) {
    _.set(state.loadings, _.join(sections, '.'), false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
