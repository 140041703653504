import Vue from 'vue'

import CarriersApi from 'api/v1/public/carriers'

const state = {
  lists: {
    all: []
  },

  loadings: {
    all: true
  }
}

const getters = {
  all: state => state.lists.all,

  loadings: state => state.loadings
}

const actions = {
  getAll({ commit }) {
    this.dispatch('carriers/setLoading', ['all'])
    return CarriersApi.getAll()
                      .then((response) => commit('GET_ALL', response))
                      .catch((error) => console.log(error))
  },

  setLoading({ commit }, sections) {
    commit('SET_LOADING_FLAG', sections)
  },

  unsetLoading({ commit }, sections) {
    commit('UNSET_LOADING_FLAG', sections)
  }
}

const mutations = {
  ['GET_ALL'] (state, response) {
    Vue.set(state.lists, 'all', response)
    this.dispatch('carriers/unsetLoading', ['all'])
  },

  ['SET_LOADING_FLAG'] (state, sections) {
    _.set(state.loadings, _.join(sections, '.'), true)
  },

  ['UNSET_LOADING_FLAG'] (state, sections) {
    _.set(state.loadings, _.join(sections, '.'), false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
