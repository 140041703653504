import Vue from 'vue'

import SearchApi from 'api/v1/public/search'

const state = {
  lists: {
    products: []
  },

  loadings: {
    products: {
      results: false,
      started: false
    }
  }
}

const getters = {
  products: state => state.lists.products,

  loadings: state => state.loadings
}

const actions = {
  products({ commit }, query) {
    this.dispatch('search/setLoading', ['products', 'results'])
    if (!state.loadings.started) {
      this.dispatch('search/setLoading', ['products', 'started'])
    }
    return SearchApi.products(query)
                    .then((response) => commit('SEARCH_RESULT', ['products', response]))
                    .catch((error) => console.error(error))
  },

  setLoading({ commit }, sections) {
    commit('SET_LOADING_FLAG', sections)
  },

  unsetLoading({ commit }, sections) {
    commit('UNSET_LOADING_FLAG', sections)
  }
}

const mutations = {
  ['SEARCH_RESULT'] (state, [section, response]) {
    Vue.set(state.lists, section, response)
    this.dispatch('search/unsetLoading', ['products', 'results'])
  },

  ['SET_LOADING_FLAG'] (state, sections) {
    _.set(state.loadings, _.join(sections, '.'), true)
  },

  ['UNSET_LOADING_FLAG'] (state, sections) {
    _.set(state.loadings, _.join(sections, '.'), false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
