import Vue from 'vue'

import ProductsApi from 'api/v1/public/products'

const state = {
  lists: {
    combinations: []
  },

  loadings: {
    combinations: true
  }
}

const getters = {
  combinations: state => state.lists.combinations,

  loadings: state => state.loadings
}

const actions = {
  getAllCombinations({ commit }, product_id) {
    this.dispatch('products/setLoading', ['combinations'])
    return ProductsApi.getAllCombinations(product_id)
                      .then((response) => commit('GET_ALL_COMBINATIONS', response))
                      .catch((error) => console.log(error))
  },

  addToCart({ commit }, [product_id, quantity, price, combinations]) {
    return ProductsApi.addToCart(product_id, quantity, price, combinations)
                      .then((response) => commit('ADD_TO_CART', response))
                      .catch((error) => console.log(error))
  },

  removeFromCart({ commit }, product_id) {
    return ProductsApi.removeFromCart(product_id)
                      .then((response) => commit('REMOVE_FROM_CART', response))
                      .catch((error) => console.log(error))
  },

  setLoading({ commit }, sections) {
    commit('SET_LOADING_FLAG', sections)
  },

  unsetLoading({ commit }, sections) {
    commit('UNSET_LOADING_FLAG', sections)
  }
}

const mutations = {
  ['GET_ALL_COMBINATIONS'] (state, response) {
    Vue.set(state.lists, 'combinations', response)
    this.dispatch('products/unsetLoading', ['combinations'])
  },

  ['ADD_TO_CART'] (state, response) {
    // this.dispatch('products/getAllCombinations', product_id)
    // this.dispatch('products_attributes/getAll', product_id)
  },

  ['REMOVE_FROM_CART'] (state, response) {
    // this.dispatch('products/getAllCombinations', product_id)
    // this.dispatch('products_attributes/getAll', product_id)
  },

  ['SET_LOADING_FLAG'] (state, sections) {
    _.set(state.loadings, _.join(sections, '.'), true)
  },

  ['UNSET_LOADING_FLAG'] (state, sections) {
    _.set(state.loadings, _.join(sections, '.'), false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
